import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/app.scss";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faDownload } from "@fortawesome/fontawesome-free-solid";
import Home from "./components/Home";
import Login from "./components/Login";
import Reset from "./components/Reset";
import Dashboard from "./components/Admin/Dashboard";
import AddGig from "./components/Admin/AddGig";
import EditGig from "./components/Admin/EditGig";
import Press from "./components/Press";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(fab);
library.add(faDownload);

export default function App() {
  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <Routes>
        {/* <Route exact path="/press" element={<Press />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/reset" element={<Reset />} />
        <Route exact path="/admin" element={<Dashboard />} />
        <Route exact path="/add-gig" element={<AddGig />} />
        <Route exact path="/edit-gig/:id" element={<EditGig />} /> */}
        <Route exact path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}
