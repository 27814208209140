/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import SocialIcons from "./SocialIcons";

function Home() {
  const [orientation, setOrientation] = useState("");
  window.addEventListener("resize", handleResize);

  function handleResize() {
    setOrientation(
      window.innerWidth > window.innerHeight ? "landscape" : "portrait"
    );
  }

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div className={`background-${orientation}`}>
      
        <h1 className="text-center d-none pt-2 pt-md-4">Drastic//Automatic</h1>
        {/* <h2 className="d-none">D//A</h2> */}
        <SocialIcons />
        <div className="d-flex flex-column align-items-center mt-1 mt-md-2">
          {/* <h2 className="">DEBUT EP - OUT NOW</h2> */}
          {/* <a
            href="https://open.spotify.com/album/72vZUtM7aX9qYEuxljqBF6?si=KYY9L2v8RZiWnxU-ESFA5Q"
            className="main-link my-2"
          >
            <h3>LISTEN ON SPOTIFY</h3>
          </a> */}

          <iframe
            style={{ borderRadius: "12px", width: "80%" }}
            src="https://open.spotify.com/embed/artist/6tKCoRvcUqEl2vIzTFHTFm?utm_source=generator&theme=0"
            width="70%"
            height="350"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
          {/* <div className="video-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/Qsdni_7luoc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div> */}
          {/* <hr /> */}

          {/* <a href="https://open.spotify.com/track/73MZ0YqbKT0V1nRdmx6nHB?si=46fa91ad95e54c1b" className="my-2 artwork">
            <img src={rcpPromo} width="250" />
          </a> */}

          {/* <h2 className="">UPCOMING GIGS</h2>
          {upcomingGigs
            .sort((a, b) => a.Date.seconds - b.Date.seconds)
            .map((x) => (
              <a key={x.Date.toDate()} href={x.Link} className="main-link my-2">
                <h3>
                  {x.Title}{" "}
                  {new Date(x.Date.seconds * 1000)
                    .toLocaleDateString("en-GB")
                    .replaceAll("/", "-")}
                </h3>
              </a>
            ))}
          <hr /> */}

          {/* <h2 className="">MUSIC</h2>
          <a href="https://open.spotify.com/track/0k7aU6hhMh7j6AX7RCr4ET?si=37560acaf8fb49a9" className="main-link my-2"><h3>A SMASH HIT FOR THE PEOPLE</h3></a>
          <a href="https://open.spotify.com/track/3lNbMNdDn99KLJ1XPOkJ04?si=9316e8ca0206419f" className="main-link my-2"><h3>BLUETOOTH</h3></a>
          <a href="https://open.spotify.com/track/02YrPkkUfRKTPLOcKFQWlV?si=919e304519ee4251" className="main-link my-2"><h3>THE CUP FINAL</h3></a>
          <a href="https://open.spotify.com/album/2muW6KThkFiMbkrzHVSpiQ?si=g8Y7EsgbQ02HD-oeklzdew" className="main-link my-2"><h3>THE FINISH LINE</h3></a>
                    <a href="https://open.spotify.com/track/73MZ0YqbKT0V1nRdmx6nHB?si=46fa91ad95e54c1b" className="main-link my-2">
            <h3>RAVENSCOURT PARK, 2004</h3>
          </a>

          <hr />
          <h2 className="">VIDEO</h2>
          <a href="https://www.youtube.com/watch?v=Qsdni_7luoc" className="main-link my-2"><h3>RAVENSCOURT PARK, 2004</h3></a>
          <a href="https://www.youtube.com/watch?v=_umSeLtyVoU" className="main-link my-2"><h3>BLUETOOTH</h3></a>
          <a href="https://www.youtube.com/watch?v=dk5xwRDti-k" className="main-link my-2"><h3>THE CUP FINAL</h3></a>
          <a href="https://www.youtube.com/watch?v=h0lSIBfKmmM" className="main-link my-2"><h3>THE FINISH LINE</h3></a>
          <a href="https://www.youtube.com/watch?v=nOfmw0yqmJs" className="main-link my-2"><h3>A SMASH HIT FOR THE PEOPLE</h3></a> */}

          {/* <hr />
          <a href="/press" className="main-link my-2"><h3>PRESS</h3></a>
        <hr />
          <h2 className="">PAST GIGS</h2>
          {pastGigs.sort((a, b) => b.Date.seconds - a.Date.seconds).map((x) => 
            <div key={x.Date.toDate()} className="past-gig my-2"><h3>{x.Title} {new Date(x.Date.seconds * 1000).toLocaleDateString("en-GB").replaceAll('/', '-')}</h3></div>
          )}
        */}
        </div>
     
    </div>
  );
}

export default Home;
