import React from "react";
import { Row, Col } from "react-bootstrap";

const links = [
  {
    link: "https://www.instagram.com/drasticautomatic/",
    class: "fa-instagram",
  },
  {
    link: "https://open.spotify.com/artist/6tKCoRvcUqEl2vIzTFHTFm?si=onpMZZkVTXyhyGkWL0WIEg",
    class: "fa-spotify",
  },
  {
    link: "https://soundcloud.com/drastic-automatic",
    class: "fa-soundcloud",
  },
  {
    link: "https://www.facebook.com/drasticautomatic",
    class: "fa-facebook",
  },
  {
    link: "https://twitter.com/DslashslashA",
    class: "fa-twitter",
  },
  {
    link: "https://www.tiktok.com/@drasticautomatic",
    class: "fa-tiktok",
  },
];

function Music() {
  return (
    <Row className="py-1 py-md-5 px-0 mx-0">
      <Col xs={6} className="pr-5 mr-5">
        <div className="d-flex flex-column align-items-start align-items-lg-start justify-content-start justify-content-lg-start flex-wrap">
          <div className="py-lg-2 d-flex flex-column flex-lg-row flex-wrap w-100">
            <a
              href="https://soundcloud.com/drastic-automatic"
              className="external-link flex-shrink"
              target="_blank"
              rel="noreferrer"
            >
              Soundcloud
            </a>
            <a
              href="https://www.youtube.com/channel/UCuDa38sdjeaJO0CRvTAj8-A"
              className="external-link"
              target="_blank"
              rel="noreferrer"
            >
              Youtube
            </a>
          </div>
          <div className="py-lg-2 d-flex flex-column flex-lg-row flex-wrap w-100">
            <a
              href="https://open.spotify.com/artist/6tKCoRvcUqEl2vIzTFHTFm?si=onpMZZkVTXyhyGkWL0WIEg"
              className="external-link"
              target="_blank"
              rel="noreferrer"
            >
              Spotify
            </a>
            <a
              href="https://drasticautomatic.bandcamp.com/"
              className="external-link"
              target="_blank"
              rel="noreferrer"
            >
              Bandcamp
            </a>
          </div>
        </div>
      </Col>
      <Col xs={6} className="pl-5 ml-5">
        <div className="d-flex flex-column align-items-end align-items-lg-end justify-content-end justify-content-lg-end flex-wrap">
          <div className="py-lg-2 d-flex flex-column flex-lg-row flex-wrap justify-content-end w-100">
            <a
              href="https://www.instagram.com/drasticautomatic/"
              className="external-link right"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
            <a
              href="https://www.facebook.com/drasticautomatic"
              className="external-link right"
              target="_blank"
              rel="noreferrer"
            >
              Facebook
            </a>
          </div>
          <div className="py-lg-2 d-flex flex-column flex-lg-row  flex-wrap justify-content-end w-100">
            <a
              href="https://www.tiktok.com/@drasticautomatic"
              className="external-link right"
              target="_blank"
              rel="noreferrer"
            >
              TikTok
            </a>
            <a
              href="https://twitter.com/DslashslashA"
              className="external-link right"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
          </div>
        </div>
      </Col>
    </Row>
    // <div className="d-flex w-100 justify-content-between music-icons-container">
    //   <div className="links-subcontainer d-flex justify-content-center flex-wrap">
    //     <a
    //       href="https://www.instagram.com/drasticautomatic/"
    //       className="fa-instagram"
    //       target="_blank"
    //       rel="noreferrer"
    //     >
    //       Instagram
    //     </a>
    //   </div>
    //   <div className="links-subcontainer"></div>
    // </div>
  );
}

export default Music;
